/**
 * Module dependencies.
 */

import { NextSeo, NextSeoProps } from 'next-seo';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

/**
 * Constants.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * Export `SEO` component.
 */

export const SEO = (props: NextSeoProps) => {
  const { asPath } = useRouter();
  const { t } = useTranslation();

  return (
    <NextSeo
      canonical={absoluteUrlResolve(
        asPath.replace(/#.*/, '').replace(/\?.*/, '')
      )}
      nofollow={!isProduction}
      noindex={!isProduction}
      openGraph={{
        description: t('common:metatags.description'),
        images: [
          {
            alt: 'BRP',
            height: 900,
            url: absoluteUrlResolve('/images/og-image.jpg'),
            width: 1600
          }
        ],
        title: t('common:metatags.title'),
        type: 'website'
      }}
      {...props}
    />
  );
};
