/**
 * Module dependencies.
 */

import { GetStaticProps, NextPage } from 'next';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `MaintenanceCard` styled component.
 */

const MaintenanceCard = styled.div`
  background-color: transparent;
  border-radius: 0;
  padding: 115px 16px 0;

  ${media.min.md`
    background-color: var(--color-blue100);
    border-radius: 16px;
    padding: 32px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  as: 'h1',
  variant: 'h1'
})`
  color: var(--color-black);
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

/**
 * `Maintenance` page.
 */

const Maintenance: NextPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t('common:maintenance.metatags.title')} />

      <MaintenanceCard>
        <Title>{t('common:maintenance.title')}</Title>

        {t('common:maintenance.description')}
      </MaintenanceCard>
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common', 'auth'])),
      layout: 'auth'
    },
    revalidate: 60
  };
};

/**
 * Export `Maintenance` page.
 */

export default Maintenance;
